
import { computed, defineComponent, reactive, toRef, toRefs } from "vue";
import { useStore } from "@/store";
import { usePicker, PickerType, Picker, UfUploadFileType, useDate } from "@/composables";
import { userService } from "@/service";
import { Toast } from "vant";
import { useRouter } from "vue-router";
import { RouterName } from "@/router";
import validate from "@/utils/validate";
const getDefaultForm = () => ({
  name: "",
  mobile: "",
  email: "",
  id_card: "",
  department_name: "",
  duty_name: "",
  subgroup_name: ""
});

interface Certificate {
  id: number;
  certificateName: string;
  certificateTypePicker: Picker<"Single">;
  certificateFile: UfUploadFileType[];
}

interface DataProps {
  formData: ReturnType<typeof getDefaultForm>;
  idcardData: UfUploadFileType[];
  certificates: Certificate[];
  daterangePickerIsShow: boolean;
  daterangePickerData: Date[];
}

interface ErrorInfo<T> {
  values: T;
  errors: {
    name: string;
    message: string;
  }[];
}

export default defineComponent({
  name: "StaffAuth",
  setup() {
    const store = useStore();
    const router = useRouter();
    const { picker: genderPicker } = usePicker(PickerType.Single.GENDER);
    const { daterangePickerDateFormat } = useDate();
    const mobile = computed(() => store.state.userInfo?.mobile);
    const staffData = reactive<DataProps>({
      formData: {
        ...getDefaultForm(),
        mobile: store.state.userInfo?.mobile as string
      },
      idcardData: [],
      certificates: [],
      daterangePickerIsShow: false,
      daterangePickerData: []
    });

    const addCertificate = () => {
      const { picker } = usePicker(PickerType.Single.CERTIFICATE_TYPE);
      staffData.certificates.push({
        id: new Date().getTime(),
        certificateName: "",
        certificateTypePicker: picker,
        certificateFile: []
      });
    };
    const removeCertificate = (index: number) => {
      staffData.certificates.splice(index, 1);
    };

    const validityOfIDCard = daterangePickerDateFormat(toRef(staffData, "daterangePickerData"));

    const onSubmit = async (values: DataProps["formData"]) => {
      const certificate = staffData.certificates
        .filter(certificate => certificate.certificateFile.length !== 0)
        .map(certificate => ({
          type_id: certificate.certificateTypePicker.value,
          photo_id: certificate.certificateFile[0].res.id
        }));
      try {
        await userService.authentication({
          type: "1",
          name: values.name,
          mobile: values.mobile,
          email: values.email,
          gender: genderPicker.value,
          department_name: values.department_name,
          duty_name: values.duty_name,
          subgroup_name: values.subgroup_name,
          positive: staffData.idcardData[0]?.res.id,
          back: staffData.idcardData[1]?.res.id,
          id_card: values.id_card,
          start_time: validityOfIDCard.value.startTimestamp,
          end_time: validityOfIDCard.value.endTimestamp,
          certificate
        });
        await router.push({
          name: RouterName.AUTHENTICATION_DETAIL
        });
      } catch (error) {}
    };

    const onFailed = (errorInfo: ErrorInfo<DataProps["formData"]>) => {
      Toast.fail(errorInfo.errors[0].message);
    };

    addCertificate();

    return {
      ...toRefs(staffData),
      onSubmit,
      onFailed,
      genderPicker,
      addCertificate,
      removeCertificate,
      validityOfIDCard,
      store,
      validate,
      mobile
    };
  }
});
